<template>
    <div class="newsBg">
        <div class="bold news_title" >
            {{info.title}}
        </div>
        <div class="news_header_info">
            <img :src="info.authorImgUrl"></img>
            <div >
                <div class="author bold">
                    <span>{{info.author}}</span>
                </div>
                <div class="publishTime">
                    {{info.publishTime}}
                </div>
            </div>
        </div>
        <div  v-html="info.content" class="ql-editor" >

        </div>
        <div class="infoSource">
            来源: {{info.infoSource}}
        </div>
    </div>

</template>

<script>
    import wx from 'weixin-js-sdk'
    import {getInformationDetails, newsImgBaseUrl} from "../../utils/api";
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import getWXSign from "../../utils/wxSign";
    import common from "../../utils/common";
    import {uwStatisticAction} from "../../utils/util";
    export default {
        name: "newsDeatail",
        data(){
            return{
                info:''
            }
        },
        mounted() {
            this.toGetInformationDetails()
            uwStatisticAction('/newsDetail','乡村新闻详情')
        },
        beforeRouteEnter (to, from, next) {
            console.log("进入路由news beforeRouteEnter")
            var u = navigator.userAgent;
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            // XXX: 修复iOS版微信HTML5 History兼容性问题
            if (isiOS && to.path !== location.pathname) {
                // 此处不可使用location.replace
                location.assign(to.fullPath)
                console.log("ios设备")
            } else {
                next()
            }
        },
        methods:{
            toGetInformationDetails(){
                let self = this
                getInformationDetails({
                    'id':this.$route.query.newsId,
                }).then(res=>{
                    // 加载状态结束
                    this.loading = false;
                    if (res.code == 200){
                        if (res.data.businessCode && res.data.businessCode === 200) {
                                self.info = res.data.data;
                                let imgUrl =JSON.parse(this.info.imagesUrl)[0].replace('fileTransferPath',newsImgBaseUrl)
                                console.log("tupian:",imgUrl)
                            getWXSign().then(()=>{
                                wx.updateAppMessageShareData({
                                    title: self.info.title, //
                                    desc: self.info.remark, // 分享描述
                                    link: location.href , // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                    imgUrl: imgUrl, // 分享图标
                                    success: function () {
                                        // 设置成功
                                    }
                                })
                                wx.updateTimelineShareData({
                                    title: self.info.title, //
                                    desc: self.info.remark, // 分享描述
                                    link: location.href , // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                    imgUrl:imgUrl, // 分享图标
                                    success: function () {
                                        // 设置成功
                                    }
                                })

                            })
                        }
                    }}).catch(()=>{
                })
            }
        }
    }
</script>

<style lang="less" >
    .newsBg{
        padding: 24px;
        p{
            line-height: 32px;
            font-size: 17px;
            margin-bottom: 15px;
            br{
                display: none;
            }
        }
        img{
            max-width: 100%;
            margin: 10px 0;
        }
        em{
            font-style: italic;
        }
        strong{
            font-weight: bold;
        }
        .news_title{
            color: #333333;
            font-size: 24px;
            margin: 8px 0 20px 0;
        }
        .news_header_info{
            background-color: white;
            display: flex;
            align-items: center;
            margin: 0 0 20px 0;
            img{
               width: 32px;
                height: 32px;
                margin:  0;
            }
            .author{
                margin-top: 0rem;
                margin-left: 10px;
                font-size: 12px;
                color: #333333
            }
            .publishTime{
                font-size: 11px;
                color: #999999;
                margin-left: 10px;
                margin-top: 4px;
            }
        }
        .infoSource{
            margin: 0 16px;
            color: #999999;
            font-size: 15px
        }
        .ql-editor{
            padding: 0;
            h1,h2,h3,h4,h5{
                font-size: 18px;
                font-weight: bold;
            }

        }
    }

</style>
